<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >

    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <img src='@/assets/images/search.svg' class="search-icon" width="70" />
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <div class="glass-names">
      <div class="glass-name all" @click="$router.push({name:'glasses'})" >Tous</div>
      <template v-for="(glass, index) in glasses">
        <div class="glass-name"  @click="$router.push({name:'editGlass', params: { id: glass.id }})" v-if="glass.active">{{ glass.name }}</div>
        
      </template>
    </div>

    <b-row v-if="!loading">
      <GlassItem
        v-for="(glass, index) in glasses"
        :key="index"
        :glass="glass"
      />
    </b-row>
  

</b-overlay>
</template>

<script>
import {BModal, BRow, BButton, VBModal, BCard, BOverlay, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import GlassItem from './GlassItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    GlassItem,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BOverlay,
    BButton
  },
  data() {
    return {
      
      
      glasses:[],
      loading:true,

      pageLength: 50,
      totalRows:0,
      currentPage:1,
      dir: false,
      selectedItem:null,
      columns: [
       
        {
          label: 'Nom',
          field: 'name',
        },
        {
          label: 'Prix',
          field: 'price',
        },
       
        {
          label: 'Action',
          field: 'action',
        },
       
      ],
      rows: [],
      searchTerm: '',

    }
  },
  watch:{
    currentPage:async function(){
      this.loading=true
      let offset=this.currentPage*this.pageLength
      if(this.searchTerm!='')
      {
        let glasses=await APIRequest.list_with_request('glasses','?active=1&limit=1000&creator_owner='+localStorage.id_user_senseye_auth+'&keyword='+this.searchTerm)
  
        this.glasses=glasses[0]
      }
      else
      {
        let glasses=await APIRequest.list_with_request('glasses','?active=1&limit=1000&creator_owner='+localStorage.id_user_senseye_auth)
  
        this.glasses=glasses[0]
      }
      
      this.loading=false
    },
    searchTerm:async function()
    {
      this.loading=true
      let offset=this.currentPage*this.pageLength
      if(this.searchTerm!='')
      {
        let glasses=await APIRequest.list_with_request('glasses','?active=1&limit=1000&creator_owner='+localStorage.id_user_senseye_auth+'&keyword='+this.searchTerm)
  
        
        this.glasses=glasses[0]
      }
      else
      {
        let glasses=await APIRequest.list_with_request('glasses','?active=1&limit=1000&creator_owner='+localStorage.id_user_senseye_auth)
  
        this.glasses=glasses[0]
      }
      
      this.loading=false
    }
  },
  methods:{
    async deleteGlass(id)
    {
      this.loading=true
      try {
        await APIRequest.delete('glasses',id)
        let glasses=await APIRequest.list_with_request('glasses','?active=1&limit=1000&creator_owner='+localStorage.id_user_senseye_auth)
  
        this.glasses=glasses[0]
        this.totalRows-=1
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La lunette a été supprimée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    },
    
  },
  
  async mounted()
  {
    let glasses=await APIRequest.list_with_request('glasses','?active=1&limit=1000&creator_owner='+localStorage.id_user_senseye_auth)
  
    this.glasses=glasses[0]
    this.totalRows=this.glasses.length
    
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
